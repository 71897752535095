import {useStaticQuery, graphql} from 'gatsby';

const diegoImage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        diego: file(relativePath: { eq: "diego-valle-jones.png" }) {
          childImageSharp {
            fixed(width: 110, quality: 95) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
        }
      }
    `
  );
  return data;
};

export default diegoImage;