import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Newsletter from "../components/Newsletter";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import AboutImages from "../components/AboutImages";
import diegoImage from "../components/diego";

import social_image from "../images/social.png";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

function AboutPage() {
  const aboutImages = AboutImages();
  const dimage = diegoImage();
  const {t} = useTranslation();
  return (
    <Layout>
      <SEO
        keywords={[`About`, "Diego Valle", "Diego Valle-Jones"]}
        title={t("About")}
        description={t("If I told you I grew up in California I would be lying, here nobody grows up")}
        image={social_image}
      />
      <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
        <section>
          <h1 className="text-center"><Trans>About Me</Trans></h1>

          <div className="pb-4">
            <div className="float-left pr-8">
              <Img
                fixed={dimage.diego.childImageSharp.fixed}
                className="float-left pr-8"
                fadeIn={false}
                loading="eager"
                imgStyle={{ overflow: "hidden" }}
                alt={"Diego Valle-Jones"}
              />
            </div>
            <p className="py-4 leading-7">
              <Trans i18nKey="about_1">
                Hi, my name is Diego Valle and that’s my picture on the left. As
                an avid programmer, I find joy in turning complex problems into
                elegant solutions through the power of code. Whether it's
                building innovative web applications, creating efficient
                algorithms, or exploring the latest trends in software
                development, I am constantly seeking ways to sharpen my coding
                skills and push the boundaries of what is possible.
              </Trans>
            </p>
            <p className="py-4 leading-7">
              <Trans i18nKey="about_2">
                But my love for programming is not limited to its technical
                aspects. I am equally captivated by the art of storytelling
                through data. Statistics, with its ability to uncover hidden
                patterns and insights, is like a treasure trove waiting to be
                discovered. Through my websites, I aim to unravel the mysteries
                behind data, sharing captivating visualizations and
                thought-provoking analyses that bring numbers to life.
              </Trans>
            </p>
            <p className="py-4 leading-7">
              <Trans i18nKey="about_3">
                And what is a good story without a compelling plot? In addition
                to programming and statistics, I have a deep appreciation for
                the art of visualization. It is through the skillful use of
                imagery and graphical representation that a story truly comes to
                life, captivating the audience and immersing them in a world of
                imagination. It breathes life into words, transforming them into
                a symphony of colors, shapes, and textures that dance before the
                eyes of the beholder.
              </Trans>
            </p>
            <p className="py-4 leading-7">
              <Trans i18nKey="about_4">
                So, whether you are a fellow programmer, a data enthusiast, or
                simply someone with a curious mind, I invite you to join me on
                this journey. Explore the depths of coding, statistics, and
                visualizations, and let's embark on a quest to unravel the
                wonders that lie within. Are you ready to dive in? Let's{" "}
                <a href="http://blog.diegovalle.net">begin</a>!
              </Trans>
            </p>
          </div>
          <hr className="pt-4" />
        </section>
        <a
          href={aboutImages.typewriter.childImageSharp.fluid.originalImg}
          aria-label="typewriter"
        >
          <Img
            fluid={aboutImages.typewriter.childImageSharp.fluid}
            sizes={{
              ...aboutImages.typewriter.childImageSharp.fluid,
              aspectRatio: 800 / 600,
            }}
            fadeIn={false}
            loading="lazy"
            imgStyle={{ overflow: "hidden" }}
            className="rounded"
          />
        </a>
        <hr className="p-4" />
        <Newsletter />
        <hr className="p-4" />
        <div className="flex mb-4 h-full  flex-wrap">
          <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 p-1">
            <a
              href={aboutImages.chalma.childImageSharp.fluid.originalImg}
              aria-label="Chalma"
            >
              <Img
                fluid={aboutImages.chalma.childImageSharp.fluid}
                sizes={{
                  ...aboutImages.chalma.childImageSharp.fluid,
                  aspectRatio: 800 / 600,
                }}
                loading="lazy"
                imgStyle={{ overflow: "hidden" }}
                fadeIn={false}
                className="rounded"
              />
            </a>
          </div>
          <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 p-1">
            <a
              href={aboutImages.ocean.childImageSharp.fluid.originalImg}
              aria-label="Ocean"
            >
              <Img
                fluid={aboutImages.ocean.childImageSharp.fluid}
                sizes={{
                  ...aboutImages.ocean.childImageSharp.fluid,
                  aspectRatio: 800 / 600,
                }}
                loading="lazy"
                imgStyle={{ overflow: "hidden" }}
                fadeIn={false}
                className="rounded"
              />
            </a>
          </div>
          <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 p-1">
            <a
              href={aboutImages.cars.childImageSharp.fluid.originalImg}
              aria-label="cars at night"
            >
              <Img
                fluid={aboutImages.cars.childImageSharp.fluid}
                sizes={{
                  ...aboutImages.cars.childImageSharp.fluid,
                  aspectRatio: 799 / 600,
                }}
                loading="lazy"
                imgStyle={{ overflow: "hidden" }}
                fadeIn={false}
                className="rounded"
              />
            </a>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default AboutPage;


export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "about", "header", "newsletter"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;