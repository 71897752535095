import {useStaticQuery, graphql} from 'gatsby';

const AboutImages = () => {
  const data = useStaticQuery (graphql`
query aboutQuery {
  
  typewriter:file(relativePath: {eq: "photos/typewriter.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

  chalma:file(relativePath: {eq: "photos/chalma.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 85) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

  ocean:file(relativePath: {eq: "photos/ocean.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

  cars:file(relativePath: {eq: "photos/cars-night.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

   
   
  
}
`);
  return data;
};

export default AboutImages;